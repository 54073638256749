<template>
  <div class="sb">
    <img class="sb-img" :src="data.icon" alt="special-buy" />
    <v-btn v-if="showButton" class="sb-icon" fab x-small @click="close">
      <v-icon color="#fff">mdi-close</v-icon>
    </v-btn>

    <v-btn
      v-if="showButton"
      outlined
      tile
      dark
      class="sb-button"
      @click="toDetailPage()"
    >
      <span class="white--text">SEE DETAIL</span>
    </v-btn>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "SpecialBuyComponent",
  props: {
    data: {
      type: Object,
			default: () => {
				return {}
			}
    }
  },
  data() {
    return {
      showButton: false,
    };
  },
  methods: {
    toDetailPage() {
      this.setPopUpExpiryDate();
      this.$router.push({
        name: "Special-Buy-View",
        params: { slug: this.data.slug },
      });
    },
    close() {
      this.setPopUpExpiryDate();
      this.$emit("close");
    },
    setPopUpExpiryDate() {
      let date = new Date();
      let addDate = moment(date)
        .add(1, "h")
        .toDate();
      localStorage.setItem("pop_up_expiry_date", addDate)
    },
  },
  mounted() {
    setTimeout(() => {
      this.showButton = true;
    }, 1000);
  },
};
</script>

<style scoped>
.sb {
  position: relative;
  width: 100%;
  height: 100%;
}
.sb-img {
  width: 100%;
  height: auto;
  border-radius: 12px;
}
.sb-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  background: linear-gradient(180deg, #525151 22.73%, #000000 72.73%);
}
.sb-button {
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translateX(-50%);
}
</style>
